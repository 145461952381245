/* Generated By: http://www.cufonfonts.com */

@font-face {
font-family: 'Vegur Regular';
font-style: normal;
font-weight: normal;
src: local('Vegur Regular'), url('Vegur-R 0.602.woff') format('woff');
}


@font-face {
font-family: 'Vegur Medium';
font-style: normal;
font-weight: normal;
src: local('Vegur Medium'), url('Vegur-M 0602.woff') format('woff');
}


@font-face {
font-family: 'Vegur Light';
font-style: normal;
font-weight: normal;
src: local('Vegur Light'), url('Vegur-L 0602.woff') format('woff');
}


@font-face {
font-family: 'Vegur Bold';
font-style: normal;
font-weight: normal;
src: local('Vegur Bold'), url('Vegur-B 0.602.woff') format('woff');
}